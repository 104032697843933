<template>
  <div>
    <Title
      :title="title"
      :criteria="criteria"
      :title-icon="titleIcon"
      v-on="$listeners" />
    <CollectionsSearchProductsList
      v-if="isProductsCriteria"
      :is-shown="isProductsCriteria"
      :show-spinner="showSpinner"
      :is-store-product-preload="isStoreProductPreload"
      :items="items"
      :is-loading-items="isLoadingItems"
      v-on="$listeners" />
    <CollapsedRows
      v-else
      :items="items"
      :criteria="criteria"
      :is-loading-items="isLoadingItems"
      v-on="$listeners" />
  </div>
</template>
<script>
export default {
  name: 'CollectionsSearchExpandedRows',
  components: {
    CollectionsSearchProductsList: () => import('@/components/Collections/CollectionsSearch/CollectionsSearchProductsList'),
    CollapsedRows: () => import('@/components/Collections/CollectionsSearch/CollectionsSearchCollapsedRows'),
    Title: () => import('../CollectionsSearchTitle'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isProductsCriteria: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    criteria: {
      type: String,
      default: null,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    titleIcon: {
      type: Object,
      default: null,
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
</style>
